<template>
  <div class="profile-data-wrapper test" v-show="!loading">
    <!--div class="profile-options">
        <div class="row align-items-center">  
          <div class="col">
            <h2 class="section-heading">Zoho Crm Api Settings</h2>
          </div>
        </div>
      </div-->
      <template
      v-if="
        userPermissions &&
        userPermissions.settings &&
        userPermissions.settings.length > 0 &&
        userPermissions.settings.includes('View')
      "
    >
    <div class="uproster-login--form">
      <form method="post" @submit.prevent="submitHandler">
        <div class="form-field-group">
          <h3 class="form-title">Zoho Crm Api Settings</h3>
          <div class="row grid-1">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label">Zoho Client Id </label>
              <div class="form-field">
                <input
                  autofocus
                  class="form-control"
                  v-model="settingOptions.ZohoClientId"
                  placeholder="Zoho Client Id"
                  type="text"
                  autocomplete="off"
                />
                <span
                  class="error"
                  v-if="submitted && v$.settingOptions.ZohoClientId.required.$invalid"
                  v-html="`The Zoho Client Id field is required.`"
                ></span>
              </div>
            </div>
          </div>
          <div class="row grid-1">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label">Zoho Client Secret </label>
              <div class="form-field">
                <input
                  class="form-control"
                  placeholder="Zoho Client Secret"
                  v-model="settingOptions.ZohoClientSecret"
                  type="text"
                  autocomplete="off"
                />
                <span
                  class="error"
                  v-if="submitted && v$.settingOptions.ZohoClientSecret.required.$invalid"
                  v-html="`The Zoho Client Secret field is required.`"
                ></span>
              </div>
            </div>
          </div>
          <div class="row grid-1">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label">Zoho Code </label>
              <div class="form-field">
                <input
                  class="form-control"
                  placeholder="Zoho Code"
                  v-model="settingOptions.ZohoCode"
                  type="text"
                  autocomplete="off"
                />
                <span
                  class="error"
                  v-if="submitted && v$.settingOptions.ZohoCode.required.$invalid"
                  v-html="`The Zoho Code field is required.`"
                ></span>
              </div>
            </div>
          </div>
          <div class="row grid-1">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label">Zoho Refresh Token </label>
              <div class="form-field">
                <input
                  class="form-control"
                  placeholder="Zoho Refresh Token"
                  v-model="settingOptions.ZohoRefreshToken"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="form-btns" v-if="
              userPermissions &&
              userPermissions.settings &&
              userPermissions.settings.length > 0 &&
              userPermissions.settings.includes('Add')
            ">
            <div class="d-flex justify-content-end">
              <button class="primary-btn " type="submit">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.settings &&
        (userPermissions.settings.length === 0 ||
          (userPermissions.settings.length > 0 &&
            !userPermissions.settings.includes('View')))
      "
      ><AccessDenied/></template
    >
  </div>
</template>
<script>
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import { library } from "@fortawesome/fontawesome-svg-core";
//import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

//library.add(faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import settingService from "../services/setting.service";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AccessDenied from "../components/AccessDenied.vue";

import common from "../mixins/common";

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      settingOptions: {
        ZohoClientId: { required },
        ZohoClientSecret: { required },
        ZohoCode: { required },
      },
    };
  },
  data() {
    return {
      submitted: false,
      loading: true,
      settingOptions: {
        ZohoClientId: "",
        ZohoClientSecret: "",
        ZohoCode: "",
      },
    };
  },
  mixins: [common],
  components:{
    AccessDenied
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const options = await settingService.getSettings();
        if (options.settingOptions !== null) {
          this.settingOptions = options.settingOptions;
        } else {
          this.settingOptions.ZohoClientId = "";
          this.settingOptions.ZohoClientSecret = "";
          this.settingOptions.ZohoCode = "";
        }
        console.log("setting Options", this.settingOptions);
        vm.$store.commit("loadingStatus", false);
      }catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async submitHandler() {
      const vm = this;

      try {
          await vm.$store.dispatch("getUserDetails").then(async(response) => {
              if(response.is_active){
                vm.v$.$touch();
                vm.submitted = true;
                const isValidate = await vm.v$.$validate();
                //console.log('formData',isValidate);
                if (!isValidate) return;

                console.log("testinggg!!!", this.settingOptions);
                vm.$store.commit("loadingStatus", true);

                let formData = new FormData();

                formData.append("ZohoClientId", vm.settingOptions.ZohoClientId);
                formData.append("ZohoClientSecret", vm.settingOptions.ZohoClientSecret);
                formData.append("ZohoCode", vm.settingOptions.ZohoCode);

                const response = await settingService.update(formData);

                console.log("setting Options", response);
                this.$store.commit("loadingStatus", false);
                vm.toastMessage('Settings Updated Successfully!', "success");
                vm.getData();
                return vm.$router.push({ name: "Settings" });
              }
          });
      } catch (error) {
        console.log("error", error);
        let message = "There was some error. Please try again later.";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }
        vm.message = message;
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
  },
};
</script>
